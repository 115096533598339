import { defineStore } from 'pinia'

export const generalStore = defineStore('general', {
  state: () => ({
    isAuthenticated: !!localStorage.getItem('access_token')
  }),
  actions: {
    setIsAuthenticated (value) {
      this.isAuthenticated = value
    }
  }
})
