import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import * as Sentry from '@sentry/vue'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import axios from 'axios'

import App from './App.vue'
import router from './router'

import { generalStore } from './stores'

const vuetify = createVuetify({
  components,
  directives
  // blueprint: md3
})

const pinia = createPinia()
const app = createApp(App)

// Sentry.init({
//   app,
//   dsn: 'https://1b1e0885673efbc4004083b97ce40317@o4507742464507904.ingest.us.sentry.io/4507742466342912',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration()
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

app.use(pinia)
app.use(vuetify)
app.use(router)

// if (import.meta.env.MODE === 'development')
//   axios.defaults.baseURL = 'http://localhost:3001'
// else axios.defaults.baseURL = 'https://api.oquecozinhar.dev.br'
// axios.defaults.baseURL = process.env.VITE_API_BASE_URL
// console.log(import.meta.env.VITE_API_BASE_URL)

const store = generalStore()

axios.defaults.baseURL = import.meta.env.VITE_BASE_API_URL
if (store.isAuthenticated) {
  axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('id_token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => Promise.reject(error)
  )
}

app.mount('#app')
