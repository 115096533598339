<script setup>
import { RouterView, useRouter } from 'vue-router'
import { generalStore } from './stores';
import axios from 'axios';

const store = generalStore();

const router = useRouter();

async function login() {
  const loginUrl = 'https://accounts.google.com/o/oauth2/v2/auth?' +
    'scope=openid email profile&' +
    'access_type=offline&' +
    'include_granted_scopes=false&' +
    'response_type=code&' +
    'state=state_parameter_passthrough_value&' +
    `redirect_uri=${window.location.origin}/oauth/callback&` +
    `client_id=${import.meta.env.VITE_GOOGLE_CLIENT_ID}`
  window.location.href = loginUrl
}

async function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
  store.setIsAuthenticated(false);
  axios.interceptors.request.use(
    config => {
      return config
    },
    error => Promise.reject(error)
  )
  router.push('/');
}
</script>

<template>
  <v-layout class="rounded rounded-md">
    <v-app-bar>
      <v-app-bar-title>O que cozinhar?</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="store.isAuthenticated" @click="$router.push('/recipeTreatment')">Receitas</v-btn>
      <v-btn v-if="store.isAuthenticated" @click="$router.push('/ingredientTreatment')">Ingredientes</v-btn>
      <v-btn @click="$router.push('/recipes')">Pesquisa</v-btn>
      <v-btn v-if="!store.isAuthenticated" @click="login">Login</v-btn>
      <v-btn v-if="store.isAuthenticated" @click="logout">Logout</v-btn>
    </v-app-bar>

    <v-main class="align-center justify-center" style="min-height: 300px;">
      <RouterView />
    </v-main>
  </v-layout>
</template>
