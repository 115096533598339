import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '@/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/oauth/callback',
      name: 'callback',
      component: () => import('../views/CallbackView.vue')
    },
    {
      path: '/recipeTreatment',
      name: 'recipeTreatment',
      component: () => import('../views/RecipeTreatmentV2View.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ingredientTreatment',
      name: 'ingredientTreatment',
      component: () => import('../views/IngredientTreatmentV2View.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('../views/StatsView.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/recipes',
      name: 'recipes',
      component: () => import('../views/RecipesView.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next('/')
  } else {
    next()
  }
})

export default router
